import { React, Component } from "react";

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="rounded bg-white mb-0 px-6 py-6 flex">
                        <div className="preloader ml-1"></div>
                        <h6 className="mx-2 mt-1 text-center text-2xl font-semibold text-gray-600">
                            {this.props.text ?? "Cargando..."}
                        </h6>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
        );
    }
}

export default Loader;