import { React, Component } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader.js";

class ProveedorRegistro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nit: "",
      nombre: "",
      correo: "",
      banco: "",
      bancoTipo: "",
      bancoNumero: "",
      vendedorCedula: "",
      vendedorNombre: "",
      vendedorCorreo: "",
      vendedorTelefono: "",
      fileRut: null,
      fileCamara: null,
      fileBanco: null,

      showModal: false,
      isLoading: false,
      loadingText: "Cargando..."
    };
  }

  componentDidMount() {
  }

  validate() {
    var validacion = {
      Estado: "OK",
      Detalle: []
    };

    if (this.state.nit.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El nit es requerido");
    }
    if (this.state.nombre.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El nombre es requerido");
    }
    if (this.state.correo.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El correo es requerido");
    }
    if (this.state.banco == "0") {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El banco es requerido");
    }
    if (this.state.bancoTipo == "") {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El tipo de banco es requerido");
    }
    if (this.state.bancoNumero.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El número de cuenta es requerido");
    }
    if (this.state.vendedorCedula.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("La cédula del vendedor es requerida");
    }
    if (this.state.vendedorNombre.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El nombre del vendedor es requerido");
    }
    if (this.state.vendedorCorreo.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El correo del vendedor es requerido");
    }
    if (this.state.vendedorTelefono.length < 1) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El teléfono del vendedor es requerido");
    }

    if (this.state.fileRut == null) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El archivo de rut es requerido");
    }
    if (this.state.fileCamara == null) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El archivo de cámara es requerido");
    }
    if (this.state.fileBanco == null) {
      validacion.Estado = "ERROR";
      validacion.Detalle.push("El archivo de cuenta bancaria es requerido");
    }

    return validacion;
  }

  async save() {    
    var validacion = this.validate();
    if (validacion.Estado == "OK") {
      this.setLoading(true, "Guardando...");

      var resFileRut = await global.server.uploadFile(this.state.fileRut, "rut.pdf", "PRIMO/PROVEEDORES/RUT")
      if (resFileRut.Estado != "OK") {
        global.utils.showError(resFileRut);
        return;
      }
      var resFileCamara = await global.server.uploadFile(this.state.fileCamara, "camara.pdf", "PRIMO/PROVEEDORES/CAMARA")
      if (resFileCamara.Estado != "OK") {
        global.utils.showError(resFileCamara);
        return;
      }
      var resFileBanco = await global.server.uploadFile(this.state.fileBanco, "banco.pdf", "PRIMO/PROVEEDORES/BANCO")
      if (resFileBanco.Estado != "OK") {
        global.utils.showError(resFileBanco);
        return;
      }

      var data = {
        EmpresaNit: this.state.nit,
        EmpresaNombre: this.state.nombre,
        EmpresaEmail: this.state.correo,
        Banco: this.state.banco,
        BancoTipo: this.state.bancoTipo,
        BancoNumero: this.state.bancoNumero,
        VendedorCedula: this.state.vendedorCedula,
        VendedorNombre: this.state.vendedorNombre,
        VendedorEmail: this.state.vendedorCorreo,
        VendedorTelefono: this.state.vendedorTelefono,
        FileRut: resFileRut.ObjetoRes.FileKey,
        FileCamara: resFileCamara.ObjetoRes.FileKey,
        FileBanco: resFileBanco.ObjetoRes.FileKey,
      };
      var res = await global.server.saveProveedor(data);
      if (res.Estado === "OK") {
        global.utils.showMessage(
          {
            title: "Datos guardados",
            message: "Su registro ha sido guardado exitosamente",
            buttons: [
              {
                label: 'Aceptar',
                onClick: () => {
                  window.location.replace("/Login")
                }
              },
            ]
          }
        );
      }
      else {
        global.utils.showError(res);
      }
      this.setLoading(false, "");
    }
    else {
      global.utils.showError(validacion);
    }
  }

  setLoading(isLoading, loadingText) {
    this.setState({ isLoading: isLoading, loadingText: loadingText });
  }

  render() {
    return (
      <>
        {
          this.state.isLoading
            ? <Loader text={this.state.loadingText} />
            : null
        }
        <div className="py-6">
          <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold text-left">Registro como Proveedor</h6>
                <Link
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  to="/login"
                >
                  Ya tengo usuario
                </Link>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Datos de la Empresa
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        NIT (Sin puntos ó espacios)
                      </label>
                      <input
                        value={this.state.nit}
                        onChange={(e) => this.setState({ nit: e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1') })}
                        maxLength={12}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Nombre Empresa
                      </label>
                      <input
                        value={this.state.nombre}
                        onChange={(e) => this.setState({ nombre: e.target.value })}
                        maxLength={200}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Correo Electronico Empresa
                      </label>
                      <input
                        value={this.state.correo}
                        onChange={(e) => this.setState({ correo: e.target.value })}
                        maxLength={100}
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Datos Bancarios
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Banco
                      </label>
                      <select
                        value={this.state.banco}
                        onChange={(e) => this.setState({ banco: e.target.value })}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        {
                          global.financialInstitutions.map((el) => {
                            return <option key={el.code} value={el.code}>{el.name}</option>;
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Tipo de Cuenta
                      </label>
                      <select
                        value={this.state.bancoTipo}
                        onChange={(e) => this.setState({ bancoTipo: e.target.value })}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        {
                          global.financialTypes.map((el) => {
                            return <option key={el.code} value={el.code}>{el.name}</option>;
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Numero de Cuenta
                      </label>
                      <input
                        value={this.state.bancoNumero}
                        onChange={(e) => this.setState({ bancoNumero: e.target.value })}
                        type="number" step="1" min="0"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Datos Vendedor
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Cedula de Ciudadania
                      </label>
                      <input
                        value={this.state.vendedorCedula}
                        onChange={(e) => this.setState({ vendedorCedula: e.target.value })}
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Nombre Completo
                      </label>
                      <input
                        value={this.state.vendedorNombre}
                        onChange={(e) => this.setState({ vendedorNombre: e.target.value })}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-8/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Correo Electronico Vendedor
                      </label>
                      <input
                        value={this.state.vendedorCorreo}
                        onChange={(e) => this.setState({ vendedorCorreo: e.target.value })}
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Telefono Movil Vendedor
                      </label>
                      <input
                        value={this.state.vendedorTelefono}
                        maxLength={10}
                        onChange={(e) => this.setState({ vendedorTelefono: e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1') })}
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Documentación
                </h6>
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      RUT
                    </label>
                    <input
                      onChange={(e) => {
                        this.setState({
                          fileRut: e.target.files[0]
                        });
                      }}
                      type="file"
                      accept="application/pdf"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Camara Comercio
                    </label>
                    <input
                      onChange={(e) => {
                        this.setState({
                          fileCamara: e.target.files[0]
                        });
                      }}
                      type="file"
                      accept="application/pdf"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Certificacion Bancaria Empresa
                    </label>
                    <input
                      onChange={(e) => {
                        this.setState({
                          fileBanco: e.target.files[0]
                        });
                      }}
                      type="file"
                      accept="application/pdf"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>


                <div className="text-right mt-6 lg:mt-12 px-4">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 w-full lg:w-4/12 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => { this.save() }}
                  >
                    Confirmar registro
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProveedorRegistro;