import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="py-6">
      <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="flex-auto px-4 py-10 pt-0">

          <div className="rounded-tl-lg rounded-tr-lg pt-10 pb-5 px-6 -mx-4 bg-gradient-to-r from-emerald-600 to-emerald-900">
            <div className="justify-center flex flex-wrap md:flex-nowrap">
              <div className="md:shrink-0">
                <img className="h-36" src="/images/logo.png" alt="Primo Logo" />
              </div>
              <h1 className="my-6 font-bold text-gray-100 uppercase md:text-right text-2xl md:pl-32 lg:pl-32 sm:pl-0">
                Bienvenidos al Portal de Proveedores
                <br></br>
                <p className="text-xs lowercase">
                  ¡Ingresa al cambio y progresemos juntos!
                </p>
              </h1>
            </div>
          </div>

          <p className="px-6 pt-3 text-lg font-semibold">
            Hola, este es el nuevo portal de proveedores de Supermercados El Primo. Es el único medio por el cual podrás relacionar tus cuentas de cobro.
          </p>

          <hr className="mt-3 border-b-1 border-blueGray-300" />

          <h6 className="px-6 text-3xl mt-6 mb-3 font-bold uppercase">
            Recuerda:
          </h6>

          <div className="px-8">

            <div className="flex flex-wrap items-center pb-3">
              <h6 className="w-1/12 px-1 text-6xl font-bold uppercase text-emerald-900 text-right">
                1
              </h6>
              <p className="w-11/12 pl-6 text-lg align-middle font-semibold break-words">
                Las Facturas Electrónicas deben llegar al correo recepcion@supermercadoselprimo.com
              </p>
            </div>

            <div className="flex flex-wrap items-center pb-3">
              <h6 className="w-1/12 px-1 text-6xl font-bold uppercase text-emerald-900 text-right">
                2
              </h6>
              <p className="w-11/12 pl-6 text-lg align-middle font-semibold break-words">
                Diligenciar en el campo de Comentario u observaciones el numero de orden de compras suministrado por nuestro ejecutivo.
              </p>
            </div>

            <div className="flex flex-wrap items-center pb-3">
              <h6 className="w-1/12 px-1 text-6xl font-bold uppercase text-emerald-900 text-right">
                3
              </h6>
              <p className="w-11/12 pl-6 text-lg align-middle font-semibold break-words">
                Para tu registro debes tener a la mano RUT, Cámara de Comercio y Certificación Bancaria de tu Empresa en formato PDF.
              </p>
            </div>

          </div>

          <div className="text-center flex px-6 pt-4">
            <Link
              className="bg-blue-500 text-white active:bg-blueGray-600 text-lg font-bold uppercase py-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 w-full ease-linear transition-all duration-150 mr-2"
              type="button"
              to="/register"
            >
              Registrarme como proveedor
            </Link>
            <Link
              className="bg-emerald-500 text-white active:bg-blueGray-600 text-lg font-bold uppercase py-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 w-full ease-linear transition-all duration-150"
              type="button"
              to="/login"
            >
              Ingresar con mi usuario
            </Link>
          </div>

          <hr className="mt-3 border-b-1 border-blueGray-300" />

          <p className="pt-3 px-6 text-sm align-middle">
            *Esto no termina aquí, pronto podrás ver en el portal sus Comprobantes de Pago, Notas de Proveedor, Obtener Certificados, entre Otras.
            <br></br>
            Si tienes dudas comunícate con nosotros al 311 866 3759
          </p>

        </div>
      </div>
    </div>
  );
}
