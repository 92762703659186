import { React, Component } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader.js";

class ProformaRegistro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            fecha: null,
            banco: "",
            bancoTipo: "",
            bancoNumero: "",
            observacion: "",

            actSede: "",
            actConcepto: "",
            actNumDoc: "",
            actValor: "",
            actRetFuente: "",
            actRetIca: "",
            actDetalle: "",
            actIndex: -1,

            items: [],
            total: 0,

            showModal: false,
            isLoading: false,
            loadingText: "Cargando...",
            addText: "Agregar"
        };
    }

    componentDidMount() {
        var user = global.utils.getUser();
        this.setState({
            user: user,
            fecha: new Date(),
            banco: user.Banco,
            bancoTipo: user.BancoTipo,
            bancoNumero: user.BancoNumero
        });

        var localItems = JSON.parse(localStorage.getItem('items'));
        if (localItems != null) {
            this.setState({ items: localItems }, () => {
                this.calculateTotal();
            });
        }

        // this.setState({
        //     actSede: "CENTRO",
        //     actConcepto: "FACTURA",
        //     actNumDoc: "64",
        //     actValor: "100",
        //     actRetFuente: "10",
        //     actRetIca: "10"
        // });
    }

    componentWillUnmount() {
    }

    show(item) {
        global.utils.showMessage(
            {
                title: "Fila seleccionada",
                message:
                    "¿Desea eliminar o modificar la fila seleccionada?" +
                    "\n" +
                    "- Sede: " + item.Sede +
                    "\n" +
                    "- Concepto: " + item.Concepto +
                    "\n" +
                    "- Número de documento: " + item.NumDoc +
                    "\n" +
                    "- Valor: " + global.utils.formatMoney(item.Valor) +
                    "\n" +
                    "- Retención de fuente: " + global.utils.formatMoney(item.RetFuente) +
                    "\n" +
                    "- Retención de ica: " + global.utils.formatMoney(item.RetIca) +
                    "\n" +
                    "- Total: " + global.utils.formatMoney(item.Total)
                ,
                buttons: [
                    {
                        label: 'Cancelar',
                        onClick: () => { },
                        isCancel: true
                    },
                    {
                        label: 'Eliminar',
                        onClick: () => {
                            global.utils.showMessage(
                                {
                                    title: "Confirmación",
                                    message: "¿Esta seguro que desea eliminar la fila seleccionada?",
                                    buttons: [
                                        {
                                            label: 'Cancelar',
                                            onClick: () => { },
                                            isCancel: true
                                        },
                                        {
                                            label: 'Eliminar',
                                            onClick: () => {
                                                this.deleteItem(item);
                                            }
                                        },
                                    ]
                                }
                            );
                        }
                    },
                    {
                        label: 'Editar',
                        onClick: () => {
                            this.editItem(item);
                        }
                    },
                ]
            }
        );
    }

    deleteItem(item) {
        var items = this.state.items;
        var index = items.indexOf(item);
        if (index > -1) {
            items.splice(index, 1);
            if (index === this.state.actIndex) {
                this.setState({
                    addText: "Agregar",
                    actIndex: -1
                });
            }
        }
        this.setState({ items: items }, () => {
            this.calculateTotal();
            localStorage.setItem("items", JSON.stringify(items));
        });
    }

    editItem(item) {
        var items = this.state.items;
        var index = items.indexOf(item);
        this.setState({
            actSede: item.Sede,
            actConcepto: item.Concepto,
            actNumDoc: item.NumDoc,
            actValor: item.Valor,
            actRetFuente: item.RetFuente,
            actRetIca: item.RetIca,
            actDetalle: item.DetalleNota,
            addText: "Modificar",
            actIndex: index
        });
    }

    setShowModal(showModal) {
        this.setState({ showModal: showModal });
    }

    setLoading(isLoading, loadingText) {
        this.setState({ isLoading: isLoading, loadingText: loadingText });
    }

    async save() {
        this.setLoading(true, "Guardando...");
        var user = global.utils.getUser();
        var items = this.state.items;
        items.forEach(item => {
            item.Item = items.indexOf(item) + 1;
        });
        var observaciones = this.state.observacion;
        if (!observaciones.includes("[Notas Credito]")) {
            if (items.filter(item => item.Concepto === "NOTACREDITO").length > 0) {
                observaciones = observaciones + "\n\n[Notas Credito]";
                items.forEach(item => {
                    if (item.Concepto === "NOTACREDITO") {
                        observaciones = observaciones + "\n" + item.Item + " - " + item.Concepto + " - " + item.NumDoc + " - " + item.DetalleNota;
                    }
                });
            }
        }
        var data = {
            Nit: user.EmpresaNit,
            Fecha: this.state.fecha,
            Banco: this.state.banco,
            BancoTipo: this.state.bancoTipo,
            BancoNumero: this.state.bancoNumero,
            Observacion: observaciones,
            Items: items,
            Total: this.state.total
        };
        console.log(data);
        var res = await global.server.saveProforma(data);
        if (res.Estado === "OK") {
            this.clean();
            global.utils.showMessage(
                {
                    title: "Datos guardados",
                    message: "La relación de facturas se ha guardado correctamente.",
                    buttons: [
                        {
                            label: 'Aceptar',
                            onClick: () => { }
                        },
                    ]
                }
            );
        }
        else {
            global.utils.showError(res);
        }
        this.setLoading(false, "");
    }

    clean() {
        this.setState({
            actSede: "",
            actConcepto: "",
            actNumDoc: "",
            actValor: "",
            actRetFuente: "",
            actRetIca: "",
            actDetalle: "",
            addText: "Agregar",
            actIndex: -1,
            items: [],
            total: 0,
            observacion: ""
        });
        localStorage.removeItem("items");
    }

    validateItem() {
        var actSede = this.state.actSede;
        var actConcepto = this.state.actConcepto;
        var actNumDoc = this.state.actNumDoc;
        var actValor = this.state.actValor;
        var actRetFuente = this.state.actRetFuente;
        var actRetIca = this.state.actRetIca;

        var validacion = {
            Estado: "OK",
            Detalle: []
        };
        if (actSede == "") {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("Debe seleccionar una sede");
        }
        if (actConcepto == "") {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("Debe seleccionar un concepto");
        }
        if (actNumDoc == "") {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("Debe ingresar un número de documento");
        }
        if (actValor == "") {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("Debe ingresar un valor");
        } else if (isNaN(actValor)) {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("El valor debe ser numérico");
        } else if (actValor <= 0) {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("El valor debe ser mayor a cero");
        }
        if (actRetFuente == "") {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("Debe ingresar una retención de fuente, si no tiene dejar en 0");
        }
        if (actRetIca == "") {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("Debe ingresar una retención de ica, si no tiene dejar en 0");
        }

        var total = actValor - actRetFuente - actRetIca;
        if (total < 0) {
            validacion.Estado = "ERROR";
            validacion.Detalle.push("La suma de retenciones no puede ser mayor al valor");
        } else {
            if (actRetFuente > actValor * 0.025) {
                validacion.Estado = "ERROR";
                validacion.Detalle.push("La retención de fuente no puede ser mayor al 2.5% del valor");
            }
            if (actRetIca > actValor * 0.006) {
                validacion.Estado = "ERROR";
                validacion.Detalle.push("La retención de ica no puede ser mayor al 0.6% del valor");
            }
        }

        return validacion;
    }

    addItem() {
        var validacion = this.validateItem();
        if (validacion.Estado == "OK") {
            var items = this.state.items;
            var total = this.state.actValor - this.state.actRetFuente - this.state.actRetIca;
            if (this.state.actConcepto != "FACTURA") {
                total = total * -1;
            }

            var newItem = {
                Sede: this.state.actSede,
                Concepto: this.state.actConcepto,
                NumDoc: this.state.actNumDoc,
                Valor: this.state.actValor,
                RetFuente: this.state.actRetFuente,
                RetIca: this.state.actRetIca,
                Total: total,
                DetalleNota: this.state.actDetalle
            };
            if (this.state.actIndex > -1) {
                items[this.state.actIndex] = newItem;
            } else {
                items.push(newItem);
            }
            this.setState({ items: items }, () => {
                this.calculateTotal();
            });
            localStorage.setItem("items", JSON.stringify(items));
            this.setState({
                actSede: "",
                actConcepto: "",
                actNumDoc: "",
                actValor: "",
                actRetFuente: "",
                actRetIca: "",
                actDetalle: "",
                addText: "Agregar",
                actIndex: -1
            });
        } else {
            global.utils.showError(validacion);
        }
    }

    calculateTotal() {
        var total = this.state.items.reduce((a, b) => a + b.Total, 0);
        this.setState({ total: total });
    }

    render() {
        var tableHeaderClassName = "px-6 align-middle border border-solid py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left";
        return (
            <>
                {
                    this.state.isLoading
                        ? <Loader text={this.state.loadingText} />
                        : null
                }
                <div className="py-6">
                    <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-tl-lg rounded-tr-lg pt-10 pb-5 px-6 bg-gradient-to-r from-emerald-600 to-emerald-900">
                            <div className="justify-center flex flex-wrap md:flex-nowrap">
                                <div className="md:shrink-0">
                                    <img className="h-36" src="/images/logo.png" alt="Primo Logo" />
                                </div>
                                <h1 className="my-6 font-bold text-gray-100 uppercase md:text-right text-2xl md:pl-32 lg:pl-32 sm:pl-0">
                                    GRUPO EMPRESARIAL RUBIANO NAVARRO SAS
                                    <br></br>
                                    <p className="text-xs">
                                        NIT. 901.145.602-5
                                    </p>
                                    <p className="text-xs">
                                        Version: 1.0.1
                                    </p>
                                </h1>
                            </div>
                        </div>
                        <div className="flex-auto px-2 py-10 pt-0">
                            <>
                                {
                                    this.state.user
                                        ? <form>
                                            <div className="flex flex-wrap px-4">
                                                <h6 className="text-blueGray-400 text-sm mt-3 mb-3 font-bold uppercase">
                                                    Relación facturas cuenta de cobro
                                                </h6>
                                                <div className="flex-1"></div>
                                                <button
                                                    className="text-sm font-bold transition-all duration-150 underline uppercase"
                                                    type="button"
                                                    onClick={() => { 
                                                        global.utils.logOut();
                                                        window.location.replace("/")
                                                    }}
                                                >
                                                    Salir
                                                </button>
                                            </div>

                                            <div className="flex flex-wrap">
                                                <div className="w-6/12 pl-4 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Proveedor
                                                        </label>
                                                        <input
                                                            readOnly={true}
                                                            disabled={true}
                                                            value={this.state.user.EmpresaNombre}
                                                            type="text"
                                                            className="border-0 px-1 py-1 placeholder-blueGray-500 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            style={{ backgroundColor: "#cfcfcf" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-3/12 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Nit
                                                        </label>
                                                        <input
                                                            readOnly={true}
                                                            disabled={true}
                                                            value={this.state.user.EmpresaNit}
                                                            type="text"
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            style={{ backgroundColor: "#cfcfcf" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-3/12 pr-4">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Fecha
                                                        </label>
                                                        <input
                                                            readOnly={true}
                                                            disabled={true}
                                                            value={this.state.fecha.toLocaleDateString()}
                                                            type="text"
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            style={{ backgroundColor: "#cfcfcf" }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="w-8/12 pl-4 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Vendedor
                                                        </label>
                                                        <input
                                                            readOnly={true}
                                                            disabled={true}
                                                            value={this.state.user.VendedorNombre}
                                                            type="text"
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            style={{ backgroundColor: "#cfcfcf" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-4/12 pr-4">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Celular
                                                        </label>
                                                        <input
                                                            readOnly={true}
                                                            disabled={true}
                                                            value={this.state.user.VendedorTelefono}
                                                            type="text"
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            style={{ backgroundColor: "#cfcfcf" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="w-full mt-2 mb-3 border-b-1 border-blueGray-300" />
                                            <div className="flex flex-wrap">
                                                <div className="w-5/12 pl-4 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Sede
                                                        </label>
                                                        <select
                                                            value={this.state.actSede}
                                                            onChange={(e) => this.setState({ actSede: e.target.value })}
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                            {
                                                                global.sedes.map((el) => {
                                                                    return <option key={el.code} value={el.code}>{el.name}</option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="w-4/12 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Concepto
                                                        </label>
                                                        <select
                                                            value={this.state.actConcepto}
                                                            onChange={(e) => this.setState({ actConcepto: e.target.value })}
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                            {
                                                                global.conceptos.map((el) => {
                                                                    return <option key={el.code} value={el.code}>{el.name}</option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="w-3/12 pr-4">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            N° Doc
                                                        </label>
                                                        <input
                                                            value={this.state.actNumDoc}
                                                            onChange={(e) => this.setState({ actNumDoc: e.target.value })}
                                                            type="number" step="1" min="0"
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>

                                                {
                                                    (this.state.actConcepto === "NOTACREDITO")
                                                        ?
                                                        <div className="w-full px-4">
                                                            <div className="relative w-full mb-3">
                                                                <label
                                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                                    htmlFor="grid-password"
                                                                >
                                                                    Detalle de Nota
                                                                </label>
                                                                <input
                                                                    value={this.state.actDetalle}
                                                                    onChange={(e) => this.setState({ actDetalle: e.target.value })}
                                                                    type="text"
                                                                    maxLength={30}
                                                                    className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                />
                                                            </div>
                                                        </div>
                                                        : null
                                                }

                                                <div className="w-4/12 pl-4 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Valor
                                                        </label>
                                                        <input
                                                            value={this.state.actValor}
                                                            onChange={(e) => this.setState({ actValor: e.target.value })}
                                                            type="number" step="1" min="0"
                                                            className="border-0 px-1 py-1 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-3/12 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Ret. Fuente
                                                        </label>
                                                        <input
                                                            value={this.state.actRetFuente}
                                                            onChange={(e) => this.setState({ actRetFuente: e.target.value })}
                                                            type="number" step="1" min="0"
                                                            className="border-0 px-1 py-1 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-3/12 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                            htmlFor="grid-password"
                                                        >
                                                            Ret. Ica
                                                        </label>
                                                        <input
                                                            value={this.state.actRetIca}
                                                            onChange={(e) => this.setState({ actRetIca: e.target.value })}
                                                            type="number" step="1" min="0"
                                                            className="border-0 px-1 py-1 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-2/12 pr-4 pb-3 pt-5 pl-2">
                                                    <button
                                                        className={
                                                            "w-full h-full text-white active:bg-blueGray-600 text-sm font-bold uppercase rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 break-normal px-1 truncate" +
                                                            (this.state.addText === 'Agregar' ? ' bg-emerald-900' : ' bg-blue-600')
                                                        }
                                                        type="button"
                                                        onClick={() => { this.addItem() }}
                                                    >
                                                        {this.state.addText}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="w-full px-4">
                                                <div className="w-full min-h-[200px] border-collapse shadow-lg rounded-lg bg-white border-0 overflow-x-auto">
                                                    <table className="items-center w-full bg-transparent">
                                                        <thead>
                                                            <tr>
                                                                <th className={tableHeaderClassName}>
                                                                    Sede
                                                                </th>
                                                                <th className={tableHeaderClassName}>
                                                                    Concepto
                                                                </th>
                                                                <th className={tableHeaderClassName}>
                                                                    N° Doc
                                                                </th>
                                                                <th className={tableHeaderClassName + " text-right"}>
                                                                    Valor
                                                                </th>
                                                                <th className={tableHeaderClassName + " text-right"}>
                                                                    Ret. Fuente
                                                                </th>
                                                                <th className={tableHeaderClassName + " text-right"}>
                                                                    Ret. Ica
                                                                </th>
                                                                <th className={tableHeaderClassName + " text-right"}>
                                                                    Total
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.items.map((item, index) => {
                                                                    return (
                                                                        <tr
                                                                            key={index}
                                                                            className="hover:bg-gray-400 cursor-pointer border-b border-gray-200"
                                                                            onClick={(e) => this.show(item)}
                                                                            onContextMenu={(e) => {
                                                                                e.preventDefault();
                                                                                this.show(item);
                                                                            }}
                                                                        >
                                                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                                                                {item.Sede}
                                                                            </td>
                                                                            <td className="border-t-1 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                                                {item.Concepto}
                                                                            </td>
                                                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                                                {item.NumDoc}
                                                                            </td>
                                                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                                                {global.utils.formatMoney(item.Valor)}
                                                                            </td>
                                                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                                                {global.utils.formatMoney(item.RetFuente)}
                                                                            </td>
                                                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                                                {global.utils.formatMoney(item.RetIca)}
                                                                            </td>
                                                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right font-bold">
                                                                                {global.utils.formatMoney(item.Total)}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                            <div className="flex flex-wrap justify-end pt-4">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Total:
                                                </label>
                                                <div className="w-4/12 pr-4 pl-4">
                                                    <div className="relative w-full mb-3">
                                                        <input
                                                            value={
                                                                global.utils.formatMoney(this.state.total)
                                                            }
                                                            readOnly={true}
                                                            className="border-0 px-6 py-1 text-right font-bold placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="w-full mt-6 mb-3 border-b-1 border-blueGray-300" />

                                            <div className="flex flex-wrap">
                                                <div className="w-4/12 pl-4 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Banco
                                                        </label>
                                                        <select
                                                            value={this.state.banco}
                                                            onChange={(e) => this.setState({ banco: e.target.value })}
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                            {
                                                                global.financialInstitutions.map((el) => {
                                                                    return <option key={el.code} value={el.code}>{el.name}</option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="w-4/12 pr-1">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Tipo de Cuenta
                                                        </label>
                                                        <select
                                                            value={this.state.bancoTipo}
                                                            onChange={(e) => this.setState({ bancoTipo: e.target.value })}
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                            {
                                                                global.financialTypes.map((el) => {
                                                                    return <option key={el.code} value={el.code}>{el.name}</option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="w-4/12 pr-4">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Numero de Cuenta
                                                        </label>
                                                        <input
                                                            value={this.state.bancoNumero}
                                                            onChange={(e) => this.setState({ bancoNumero: e.target.value })}
                                                            type="number" step="1" min="0"
                                                            className="border-0 px-1 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="w-full mt-6 mb-3 border-b-1 border-blueGray-300" />

                                            <div className="flex flex-wrap">
                                                <div className="w-full px-4">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Observaciones
                                                        </label>
                                                        <textarea
                                                            value={this.state.observacion}
                                                            onChange={(e) => this.setState({ observacion: e.target.value })}
                                                            type="text"
                                                            rows="3"
                                                            className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-right mt-6 lg:mt-12 px-4">
                                                <button
                                                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 w-full lg:w-4/12 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => { this.save() }}
                                                >
                                                    Guardar y Enviar
                                                </button>
                                            </div>
                                        </form>
                                        : null
                                }
                            </>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProformaRegistro;