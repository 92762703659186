import { React, Component } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader.js";

class ProveedorIngreso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nit: "",
      telefono: "",
      code: "",
      showModal: false,
      isLoading: false,
      loadingText: "Cargando..."
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  setShowModal(showModal) {
    this.setState({ showModal: showModal });
  }

  setLoading(isLoading, loadingText) {
    this.setState({ isLoading: isLoading, loadingText: loadingText });
  }

  async sendSmsCode() {
    this.setLoading(true, "Validando...");
    const { nit, telefono } = this.state;
    var res = await global.server.startValidateProveedor(nit, telefono);
    if (res.Estado == "OK") {
      this.setState({ showModal: true, code: '' });
    }
    else {
      global.utils.showMessage(
        {
          title: res.Estado,
          message: res.Detalle.join("\r\n"),
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => { }
            },
          ]
        }
      );
    }
    this.setLoading(false, "");
  }

  async validateSmsCode() {
    this.setLoading(true, "Ingresando...");
    const { nit, code } = this.state;
    var res = await global.server.validateCodeProveedor(nit, code);
    if (res.Estado === "OK") {
      this.setState({ showModal: false });
      global.utils.setUser(res.ObjetoRes);
      window.location.replace("/Panel")
    }
    else {
      global.utils.showError(res);
    }
    this.setLoading(false, "");
  }

  render() {
    return (
      <>
        {
          this.state.isLoading
            ? <Loader text={this.state.loadingText} />
            : null
        }
        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h6 className="text-blueGray-700 text-xl font-bold text-left">
                      Verificación de proveedor
                    </h6>
                  </div>
                  {/*body*/}
                  <div className="relative px-6 py-8 flex-auto bg-blueGray-100">
                    <p className="text-blueGray-500 text-md leading-relaxed mb-6">
                      Se ha enviado un código de verificación por medio de SMS al telefono del Vendedor asociado al proveedor.
                      <br />
                      *El mensaje deberá ser recibido en maximo 2 minutos.
                      <br />
                      *Si no lo recibe, puede iniciar el proceso de verificación nuevamente.
                    </p>
                    <div className="relative w-full">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Codigo de Verificación
                      </label>
                      <input
                        value={this.state.code}
                        maxLength={6}
                        onChange={(e) => this.setState({ code: e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1') })}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-between py-6 px-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase py-2 px-4 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => this.setShowModal(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => this.validateSmsCode()}
                    >
                      Ingresar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-20 bg-black"></div>
          </>
        ) : null}
        <div className="py-6">
          <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold text-left">Ingresar como Proveedor</h6>
                <Link
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  to="/register"
                >
                  Quiero Registrarme
                </Link>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        NIT (Sin puntos ó espacios)
                      </label>
                      <input
                        value={this.state.nit}
                        onChange={(e) => this.setState({ nit: e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1') })}
                        maxLength={12}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Celular Vendedor
                      </label>
                      <input
                        value={this.state.telefono}
                        maxLength={10}
                        onChange={(e) => this.setState({ telefono: e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1') })}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <div className="text-right mt-6 lg:mt-12 px-4">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 w-full lg:w-4/12 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => this.sendSmsCode()}
                  >
                    Continuar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProveedorIngreso;