import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

var urlWebService = "https://devcloud.ddns.net/PrimoProveedor";
//var urlWebService = "http://localhost:58715";
var urlGetData = urlWebService + "/GetData.asmx/";
var urlSetData = urlWebService + "/SetData.ashx";
var urlMethod = urlWebService + "/Methods.asmx/";

var defaultHeaders = new Headers([
    ['UserAgent', 'Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0'],
]);

var getConfig = {
    method: 'GET',
    headers: defaultHeaders,
    cache: 'default'
};

var postHeaders = new Headers([
    ['UserAgent', 'Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/71.0.3578.98 Safari/537.36'],
    ['undefinedaccept', 'application/json'],
    ['accept-encoding', 'gzip, deflate'],
    ['Content-Type', 'application/json'],
    ['accept-language', 'en-US,en;q=0.8']
]);

var actualUser = null;

global.server = {
    sendRequest: async function (className, filter, dir, setObject, setParams) {
        var data =
        {
            className: className,
            filter: filter,
            key: '283745902359234523523095723',
            variableA: null,
            setObject: setObject,
            setCommand: setParams,
        }

        var jsonData = JSON.stringify(data);
        var encoded = global.Base64.btoa(jsonData);

        var url = "";
        var response = null;
        if (dir === "set") {
            url = urlSetData;
            response = await (await (fetch(url,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: postHeaders,
                    body: encoded,
                }
            )));
        }
        if (dir === "get") {
            url = urlGetData + 'getJson?data=' + encoded;
            response = await (await (fetch(url, getConfig)))
        }
        //console.log(url); 
        //console.log(response);
        return response.json();
    },

    uploadFile: async function (file, fileName, remotePath) {
        var devStorageUrl = "https://devcloud.ddns.net/DevStorage";
        var devStorageToken = "WWVuQ1k4cU12cHY1cW11UElpWU5uY0NoWThuMnFMRUtTSXd2WVM2TQ==";
        var url = devStorageUrl + "/File/FileController.asmx/UploadFile"
        try {
            var data = { folderPath: remotePath };
            var jsonData = JSON.stringify(data);
            var b64Data = window.btoa(jsonData);

            const formData = new FormData();
            formData.append('File', file, fileName);

            var response = await (fetch(url,
                {
                    method: 'POST',
                    headers: [
                        ['Authorization', 'Bearer ' + devStorageToken],
                        ['Data', b64Data]
                    ],
                    body: formData,
                }
            ));
            return response.json();
        }
        catch (e) {
            return {
                estado: "ERROR",
                mensaje: e.message
            };
        }
    },

    startValidateProveedor: async function (nit, telefono) {
        var url = urlMethod + 'StartValidateProveedor'
        url += "?nit=" + nit;
        url += "&telefono=" + telefono;
        try {
            var response = await (fetch(url, getConfig))
            return response.json();
        }
        catch (e) {
            return {};
        }
    },

    validateCodeProveedor: async function (nit, code) {
        var url = urlMethod + 'ValidateCodeProveedor'
        url += "?nit=" + nit;
        url += "&code=" + code;
        try {
            var response = await (fetch(url, getConfig))
            return response.json();
        }
        catch (e) {
            return {};
        }
    },

    saveProforma: async function (data) {
        var url = urlMethod + 'SaveProforma';
        url += "?rn=" + Math.random();
        try {
            var response = await (fetch(url,
                {
                    method: 'POST',
                    headers: postHeaders,
                    body: JSON.stringify(data),
                }
            ));
            return response.json();
        }
        catch (e) {
            return {};
        }
    },

    saveProveedor: async function (data) {
        var url = urlMethod + 'SaveProveedor';
        try {
            var response = await (fetch(url,
                {
                    method: 'POST',
                    headers: postHeaders,
                    body: JSON.stringify(data),
                }
            ));
            return response.json();
        }
        catch (e) {
            return {};
        }
    },
}

global.utils = {
    getUser() {
        var user = localStorage.getItem('user');
        if (user) {
            actualUser = JSON.parse(localStorage.getItem('user'));
        }
        else {
            actualUser = null;
        }
        return actualUser;
    },

    setUser(objeto) {
        actualUser = objeto;
        localStorage.setItem("user", JSON.stringify(actualUser));
    },

    logOut() {
        actualUser = null;
        localStorage.removeItem('user');
    },

    showMessage(options) {
        confirmAlert({
            closeOnClickOutside: false,
            closeOnEscape: false,
            customUI: ({ onClose }) => {
                return (
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between px-5 py-4 border-b border-solid border-blueGray-200 rounded-t">
                                <h6 className="text-blueGray-700 text-xl font-bold text-left">
                                    {options.title}
                                </h6>
                            </div>
                            {/*body*/}
                            <div className="relative px-0 py-4 flex-auto bg-blueGray-100 rounded-b">
                                <p className="text-blueGray-500 text-lg leading-relaxed mb-6 mx-6" style={{ whiteSpace: "pre-wrap" }}>
                                    {options.message}
                                </p>
                                <div className="flex items-center justify-end pt-6 px-4 rounded-b">
                                    {
                                        options.buttons.filter(x => x.isCancel)
                                            .map((button, index) => {
                                                return (
                                                    <button key={index} className="mr-1 text-red-500 background-transparent font-bold uppercase py-2 px-4 text-sm outline-none focus:outline-none ease-linear transition-all duration-150" onClick={() => {
                                                        onClose();
                                                        button.onClick();
                                                    }
                                                    }>
                                                        {button.label}
                                                    </button>
                                                )
                                            })
                                    }
                                    <div className='flex-1'></div>
                                    {
                                        options.buttons.filter(x => !x.isCancel)
                                            .map((button, index) => {
                                                return (
                                                    <button key={index} className="ml-1 bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150" onClick={() => {
                                                        onClose();
                                                        button.onClick();
                                                    }
                                                    }>
                                                        {button.label}
                                                    </button>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    },

    showError(error) {
        global.utils.showMessage(
            {
                title: error.Estado,
                message: error.Detalle.join("\n"),
                buttons: [
                    {
                        label: 'Aceptar',
                        onClick: () => { }
                    },
                ]
            }
        );
    },

    jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
    },

    zeroFill(number, width) {
        width -= number.toString().length;
        if (width > 0) {
            return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
        }
        return number + "";
    },

    formatAMPM(date, day) {
        var strTime = "";
        if (day) {
            if (
                date.getUTCMonth() === new Date(Date.now()).getUTCMonth() &&
                date.getUTCDate() === new Date(Date.now()).getUTCDate()) {
                strTime = "Hoy a las ";
            }
            else {
                strTime = global.utils.formatDate(date) + " ";
            }
        }
        var hours = date.getUTCHours();
        var minutes = date.getUTCMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        strTime += hours + ':' + minutes + ' ' + ampm;
        return strTime;
    },

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [day, month, year].join('/');
    },

    toDate(dateString) {
        var date = new Date(dateString)
        // var userTimezoneOffset = date.getTimezoneOffset() * 60000 * -1;
        // date = new Date(date.getTime() - userTimezoneOffset);
        return date;
    },

    formatMoney(number) {
        var formatter = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(number);
    },

}

global.financialInstitutions = [
    {
        "code": "0",
        "name": "Seleccione el banco..."
    },
    {
        "code": "1059",
        "name": "BANCAMIA S.A."
    },
    {
        "code": "1040",
        "name": "BANCO AGRARIO"
    },
    {
        "code": "1052",
        "name": "BANCO AV VILLAS"
    },
    {
        "code": "1013",
        "name": "BANCO BBVA COLOMBIA S.A."
    },
    {
        "code": "1032",
        "name": "BANCO CAJA SOCIAL"
    },
    {
        "code": "1066",
        "name": "BANCO COOPERATIVO COOPCENTRAL"
    },
    {
        "code": "1558",
        "name": "BANCO CREDIFINANCIERA"
    },
    {
        "code": "1051",
        "name": "BANCO DAVIVIENDA"
    },
    {
        "code": "1001",
        "name": "BANCO DE BOGOTA"
    },
    {
        "code": "1023",
        "name": "BANCO DE OCCIDENTE"
    },
    {
        "code": "1062",
        "name": "BANCO FALABELLA "
    },
    {
        "code": "1012",
        "name": "BANCO GNB SUDAMERIS"
    },
    {
        "code": "1006",
        "name": "BANCO ITAU"
    },
    {
        "code": "1060",
        "name": "BANCO PICHINCHA S.A."
    },
    {
        "code": "1002",
        "name": "BANCO POPULAR"
    },
    {
        "code": "1065",
        "name": "BANCO SANTANDER COLOMBIA"
    },
    {
        "code": "1069",
        "name": "BANCO SERFINANZA"
    },
    {
        "code": "1007",
        "name": "BANCOLOMBIA"
    },
    {
        "code": "1061",
        "name": "BANCOOMEVA S.A."
    },
    {
        "code": "1283",
        "name": "CFA COOPERATIVA FINANCIERA"
    },
    {
        "code": "1009",
        "name": "CITIBANK "
    },
    {
        "code": "1370",
        "name": "COLTEFINANCIERA"
    },
    {
        "code": "1292",
        "name": "CONFIAR COOPERATIVA FINANCIERA"
    },
    {
        "code": "1289",
        "name": "COTRAFA"
    },
    {
        "code": "1551",
        "name": "DAVIPLATA"
    },
    {
        "code": "1303",
        "name": "GIROS Y FINANZAS"
    },
    {
        "code": "1507",
        "name": "NEQUI"
    },
    {
        "code": "1151",
        "name": "RAPPIPAY"
    },
    {
        "code": "1019",
        "name": "SCOTIABANK COLPATRIA"
    },
    {
        "code": "1637",
        "name": "IRIS"
    },
    {
        "code": "1291",
        "name": "COOFINEP COOPERATIVA FINANCIERA"
    }
];

global.financialTypes = [
    {
        "code": "",
        "name": "Seleccione tipo de cuenta..."
    },
    {
        "code": "AHORROS",
        "name": "Cuenta de Ahorros"
    },
    {
        "code": "CORRIENTE",
        "name": "Cuenta Corriente"
    },
];

global.sedes = [
    {
        "code": "",
        "name": "Seleccione la sede..."
    },
    {
        "code": "CUNDUY",
        "name": "Cunduy (Principal)"
    },
    {
        "code": "CENTRO",
        "name": "Centro"
    },
    {
        "code": "NUEVAFLO",
        "name": "Nueva Florencia"
    },
    {
        "code": "VENTILADOR",
        "name": "Ventilador"
    },
    {
        "code": "CIUDADELA",
        "name": "Ciudadela"
    },
];

global.conceptos = [
    {
        "code": "",
        "name": "Seleccione el concepto..."
    },
    {
        "code": "FACTURA",
        "name": "Factura"
    },
    {
        "code": "DEVOLUCION",
        "name": "Nota Devolucion"
    },
    {
        "code": "NOTACREDITO",
        "name": "Nota Credito"
    },
];