import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "./assets/styles/tailwind.css";
import {
  BrowserRouter, Routes,
  Route,
} from "react-router-dom";

import './Model.js'

import Home from "./components/Home.js";
import ProveedorRegistro from "./components/ProveedorRegistro.js";
import ProveedorIngreso from "./components/ProveedorIngreso.js";
import ProformaRegistro from "./components/ProformaRegistro.js";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="flex flex-wrap bg-slate-300">
      <div className="w-full max-w-4xl mx-auto px-4">
        {
          (global.utils.getUser())
            ? <ProformaRegistro />
            : <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<ProveedorRegistro />} />
                <Route path="/login" element={<ProveedorIngreso />} />
              </Routes>
            </BrowserRouter>
        }

      </div>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
